.post-title-admin {
  font-size: 20px;
  font-weight: 700;
}
.rtc-title {
  color: #7e7d7d;
}

.rtc-translator li[data-selected='true'] {
  box-shadow: inset 0 0 1px 1px rgb(212, 211, 211);
}

.filter-day {
  min-width: 100px;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}

.post-title-user {
  font-size: 20px;
  font-weight: 700;
}

@primary-color: #1DA57A;